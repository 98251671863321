<template>
  <div class="auth-wrapper auth-v1 px-2" style="min-height: 100vh">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo align-items-center" to="/map">
          <!-- <logo /> -->
          <b-img :src="logo" alt="Ecoplot" width="40" height="40"></b-img>
          <h2 class="brand-text text-primary ml-1 mb-0">Ecoplot</h2>
        </b-link>

        <b-card-title class="mb-1">{{ $t('Reset_Your_Passsword') }}</b-card-title>
        <b-card-text class="mb-2">{{ $t('You_can_change_your_password_for_security_reasons_or_reset_it_if_you_forget_it') }}</b-card-text>
        <!-- <button @click.stop="register">resgister</button> -->

        <!-- form -->
        <validation-observer ref="loginForm" #default="{ invalid }">
          <b-form class="auth-login-form mt-2" @submit.prevent="resetPassword">
            <!--new password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="new-pass">{{ $t('New_Password') }}</label>
              </div>
              <validation-provider #default="{ errors }" name="new-pass" rules="required">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input tabindex="2" id="new-pass" v-model="newPassword" :type="newPasswordFieldType" class="form-control-merge" :state="errors.length > 0 ? false : null" name="new-pass" :placeholder="$t('New_Password')" />
                  <b-input-group-append is-text>
                    <feather-icon class="cursor-pointer" :icon="newPasswordToggleIcon" @click="togglenewPasswordVisibility" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ $t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
            <!--confirm password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="confirm-pass">{{ $t('Confirm_Password') }}</label>
              </div>
              <validation-provider #default="{ errors }" name="confirm-pass" rules="required">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input tabindex="2" id="confirm-pass" v-model="newPasswordRepeat" :type="newPasswordRepeatFieldType" class="form-control-merge" :state="errors.length > 0 ? false : null" name="confirm-pass" :placeholder="$t('Confirm_Password')" />
                  <b-input-group-append is-text>
                    <feather-icon class="cursor-pointer" :icon="newPasswordRepeatToggleIcon" @click="togglenewPasswordRepeatVisibility" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ $t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
            <!-- submit button -->
            <b-button tabindex="3" variant="primary" type="submit" block :disabled="invalid || showloading" class="btn-submit-login-reset">
              <span v-show="!showloading" class="text-signin">{{ $t('Reset_password') }}</span>
              <span v-show="showloading" class="loading-signin">
                <div style="width: 20px; height: 20px" class="spinner-border text-light" role="status"></div>
              </span>
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>{{ $t('Remember_your_password') }} </span>
          <b-link to="/login">
            <span>{{ $t('signin') }}</span>
          </b-link>
        </b-card-text>

        <div class="divider my-2"></div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email } from '@/@core/utils/validations/validations.js'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
extend('required', {
  ...required,
  message: (field) => {
    if (field == 'Email') {
      return 'This_field_is_required'
    }
    if (field == 'Password') {
      return 'This_field_is_required'
    }
    if (field == 'new-pass') {
      return 'This_field_is_required'
    }
    if (field == 'confirm-pass') {
      return 'This_field_is_required'
    }
  },
})
export default {
  components: { ToastificationContent, ValidationObserver, ValidationProvider },
  data() {
    return {
      required,
      email,
      logo: require('/static/besec.png'),
      newPassword: '',
      newPasswordRepeat: '',
      newPasswordFieldType: 'password',
      newPasswordRepeatFieldType: 'password',
      showloading: false,
    }
  },
  mounted() {},

  computed: {
    newPasswordToggleIcon() {
      return this.newPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    newPasswordRepeatToggleIcon() {
      return this.newPasswordRepeatFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    responseResetPassWord() {
      return this.$store.state.ecoplot.responseResetPassWord
    },
  },
  watch: {
    responseResetPassWord() {
      if (this.responseResetPassWord && this.responseResetPassWord.type && this.responseResetPassWord.messages) {
        this.$toast({ component: ToastificationContent, props: { title: this.$t(this.responseResetPassWord.messages), icon: 'BellIcon', variant: this.responseResetPassWord.type && this.responseResetPassWord.type == 'error' ? 'danger' : this.responseResetPassWord.type, timeout: 2000 } })
        if (this.responseResetPassWord.type == 'success') {
          setTimeout(() => {
            this.$router.push({ path: '/login' }).catch(() => {})
          }, 2000)
        }
      }
      this.$store.commit('ecoplot/UPDATE_RESPONSE_RESET_PASSWORD', null)

      this.showloading = false
    },
  },
  methods: {
    togglenewPasswordVisibility() {
      this.newPasswordFieldType = this.newPasswordFieldType === 'password' ? 'text' : 'password'
    },
    togglenewPasswordRepeatVisibility() {
      this.newPasswordRepeatFieldType = this.newPasswordRepeatFieldType === 'password' ? 'text' : 'password'
    },
    validatePassword() {
      if (this.newPassword !== this.newPasswordRepeat) {
        this.$toast({ component: ToastificationContent, props: { title: this.$t('New_password_repeat_is_not_correct'), icon: 'BellIcon', variant: 'warning' } })
        return false
      }
      return true
    },
    resetPassword() {
      let query = this.$route.query
      if (query) {
        try {
          let token = query.token.replaceAll('%2F', '')
          if (token && this.validatePassword()) {
            this.showloading = true
            this.$store.dispatch('ecoplot/RESET_PASSWORD', { token, new_password: this.newPassword })
          }
        } catch {}
      }
    },
  },
}
</script>
<style lang="scss">
@import '@/@core/scss/vue/pages/page-auth.scss';
.btn-submit-login-reset {
  height: 38px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
